import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_navbar = _resolveComponent("navbar");
  const _component_AddVipView = _resolveComponent("AddVipView");
  const _component_van_floating_bubble = _resolveComponent("van-floating-bubble");
  const _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createBlock(_component_van_config_provider, null, {
    default: _withCtx(() => [_createVNode(_component_router_view), _createVNode(_component_navbar), _createVNode(_component_AddVipView), !_ctx.isWeb ? (_openBlock(), _createBlock(_component_van_floating_bubble, {
      key: 0,
      axis: "xy",
      magnetic: "x",
      offset: _ctx.offset,
      "onUpdate:offset": _cache[0] || (_cache[0] = $event => _ctx.offset = $event),
      icon: require('@/assets/img/serveBtn.png'),
      onClick: _ctx.onClick
    }, null, 8, ["offset", "icon", "onClick"])) : (_openBlock(), _createBlock(_component_van_floating_bubble, {
      key: 1,
      axis: "xy",
      magnetic: "x",
      offset: _ctx.offset,
      "onUpdate:offset": _cache[1] || (_cache[1] = $event => _ctx.offset = $event),
      icon: require('@/assets/img/serveBtn.png')
    }, null, 8, ["offset", "icon"]))]),
    _: 1
  });
}