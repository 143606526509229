import { watch, defineEmits, reactive, toRefs } from 'vue';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'index',
  props: {
    curIdentifyCode: null,
    identifyCode: {
      type: String,
      default: '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ'
    },
    fontSizeMin: {
      type: Number,
      default: 30
    },
    fontSizeMax: {
      type: Number,
      default: 35
    },
    backgroundColorMin: {
      type: Number,
      default: 180
    },
    backgroundColorMax: {
      type: Number,
      default: 240
    },
    colorMin: {
      type: Number,
      default: 50
    },
    colorMax: {
      type: Number,
      default: 160
    },
    lineColorMin: {
      type: Number,
      default: 40
    },
    lineColorMax: {
      type: Number,
      default: 180
    },
    dotColorMin: {
      type: Number,
      default: 0
    },
    dotColorMax: {
      type: Number,
      default: 255
    },
    contentWidth: {
      type: Number,
      default: 100
    },
    contentHeight: {
      type: Number,
      default: 30
    }
  },
  setup(props) {
    const data = reactive({
      // curIdentifyCode: ''
      // emit: defineEmits(['updateIdentifyCode'])
    }); // 数据
    defineEmits(['updateIdentifyCode']);
    const methods = {
      // 生成一个随机数
      randomNum(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      },
      // 生成一个随机的颜色
      randomColor(min, max) {
        const r = methods.randomNum(min, max);
        const g = methods.randomNum(min, max);
        const b = methods.randomNum(min, max);
        return 'rgb(' + r + ',' + g + ',' + b + ')';
      },
      drawPic() {
        const canvas = document.getElementById('s-canvas');
        const ctx = canvas.getContext('2d');
        ctx.textBaseline = 'bottom';
        // 绘制背景
        ctx.fillStyle = methods.randomColor(props.backgroundColorMin, props.backgroundColorMax);
        ctx.fillRect(0, 0, props.contentWidth, props.contentHeight);
        // 绘制文字
        for (let i = 0; i < props.curIdentifyCode.length; i++) {
          methods.drawText(ctx, props.curIdentifyCode[i], i);
        }
        methods.drawLine(ctx);
        methods.drawDot(ctx);
      },
      drawText(ctx, txt, i) {
        ctx.fillStyle = methods.randomColor(props.colorMin, props.colorMax);
        ctx.font = methods.randomNum(props.fontSizeMin, props.fontSizeMax) + 'px SimHei';
        const x = (i + 1) * (props.contentWidth / (props.curIdentifyCode.length + 1));
        const y = methods.randomNum(props.fontSizeMax, props.contentHeight - 5);
        const deg = methods.randomNum(-15, 15);
        // 修改坐标原点和旋转角度
        ctx.translate(x, y);
        ctx.rotate(deg * Math.PI / 180);
        ctx.fillText(txt, 0, 0);
        // 恢复坐标原点和旋转角度
        ctx.rotate(-deg * Math.PI / 180);
        ctx.translate(-x, -y);
      },
      drawLine(ctx) {
        // 绘制干扰线
        for (let i = 0; i < 3; i++) {
          ctx.strokeStyle = methods.randomColor(props.lineColorMin, props.lineColorMax);
          ctx.beginPath();
          ctx.moveTo(methods.randomNum(0, props.contentWidth), methods.randomNum(0, props.contentHeight));
          ctx.lineTo(methods.randomNum(0, props.contentWidth), methods.randomNum(0, props.contentHeight));
          ctx.stroke();
        }
      },
      drawDot(ctx) {
        // 绘制干扰点
        for (let i = 0; i < 5; i++) {
          ctx.fillStyle = methods.randomColor(0, 255);
          ctx.beginPath();
          ctx.arc(methods.randomNum(0, props.contentWidth), methods.randomNum(0, props.contentHeight), 1, 0, 2 * Math.PI);
          ctx.fill();
        }
      },
      refreshCode() {
        // data.curIdentifyCode = ''
        methods.makeCode(props.identifyCode, 4);
      },
      makeCode(o, l) {
        for (let i = 0; i < l; i++) {
          data.curIdentifyCode += o[methods.randomNum(0, o.length)];
        }
        // console.log(curIdentifyCode.value);
        defineEmits('updateIdentifyCode', props.curIdentifyCode);
      }
    }; // 事件

    watch(() => props.curIdentifyCode, val => {
      methods.drawPic();
    });
    return {
      ...toRefs(data),
      ...methods
    };
  }
};