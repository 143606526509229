import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './assets/js/i18n'
import './assets/js/rem'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import 'vant/es/image-preview/style'

const app = createApp(App)
// app.config.globalProperties.$isWeb = false
app.use(store)
app.use(i18n)
app.use(router)
app.mount('#app')
