import { createStore } from 'vuex'
import state from '@/store/state'
import getters from '@/store/getters'
import actions from '@/store/actions'
import mutations from '@/store/mutations'
import createLogger from 'vuex-persistedstate'

export default createStore({
  state,
  getters,
  actions,
  mutations,
  plugins: [
    createLogger({
      storage: localStorage,
      reducer (state) {
        return {
          // 需要储存的state值
          token: state.token,
          info: state.info
        }
      }
    })
  ]
})
