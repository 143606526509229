import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "s-canvas",
    onClick: _cache[0] || (_cache[0] = (...args) => _ctx.refreshCode && _ctx.refreshCode(...args))
  }, [_createElementVNode("canvas", {
    id: "s-canvas",
    width: $props.contentWidth,
    height: $props.contentHeight
  }, null, 8, _hoisted_1)]);
}