import { getUserInfo } from '@/assets/js/api'
// import { showSuccessToast } from 'vant'

export default {
  async ACTgetMyinfo ({ commit }) {
    const res = await getUserInfo()
    commit('UPDATEINFO', res.data)
    // if (res.code === 200) {
    //   showSuccessToast('更新成功！')
    // }
    return res.code
  }
}
