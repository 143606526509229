// import router from '../../router'
import store from '../../store'
import router from '../../router'
import Clipboard from 'clipboard'
import { showConfirmDialog, showSuccessToast } from 'vant'
import { globals } from '@/assets/js/global'
import { configFile, logout } from '@/assets/js/api'
import i18n from '@/assets/js/i18n'
import { socketClose } from '@/assets/js/soket'

export function onClickLeft () {
  history.back()
} // 返回上一页
export function TimeFormat (time) {
  const o = parseInt(time / 3600) // 小时
  const m = parseInt(time / 60 % 60)// 分
  const s = Math.ceil(time % 60) // 秒
  if (time <= 0) {
    return '00:00:00'
  }
  return (o < 10 ? '0' + o : o) + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s)
}// 秒转化为时分秒
export function formatDate (dates) {
  const date = new Date(dates * 1000)
  const YY = date.getFullYear() + '-'
  const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
  const hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  const ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  return YY + MM + DD + ' ' + hh + mm + ss
}
// export async function loginOut (name = null) {
//   if (store.state.token) {
//     localStorage.clear()
//     sessionStorage.clear()
//     store.commit('UPDATETOKEN', null)
//     setTimeout(e => (loginOut()), 500)
//   } else {
//     router.push('/login')
//   }
// }
export function openLottery (arr) {
  if (arr) {
    const s = arr.reduce((a, b) => parseInt(a) + parseInt(b))
    return [s > 10 ? '福' : '禄', s % 2 === 0 ? '喜' : '寿']
  } else {
    return ['--', '--']
  }
}
export function choose (arr, size) {
  const allResult = [];

  (function fn (arr, size, result) {
    const arrLen = arr.length
    if (size > arrLen) {
      return
    }
    if (size === arrLen) {
      allResult.push([].concat(result, arr))
    } else {
      for (let i = 0; i < arrLen; i++) {
        const newResult = [].concat(result)
        newResult.push(arr[i])

        if (size === 1) {
          allResult.push(newResult)
        } else {
          const newArr = [].concat(arr)
          newArr.splice(0, i + 1)
          fn(newArr, size - 1, newResult)
        }
      }
    }
  })(arr, size, [])

  return allResult
}
export function copy (e, text, message) {
  const clipboard = new Clipboard(e.target, { text: () => text })
  clipboard.on('success', e => {
    // 释放内存
    showSuccessToast(message)
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.on('error', e => {
    // 不支持复制
    // 释放内存
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.onClick(e)
}

export const removeMyInfo = function () {
  localStorage.removeItem('token')
  localStorage.removeItem('myInfo')
  localStorage.removeItem('memberId')
  localStorage.removeItem('socket_link')
  sessionStorage.clear()
  store.commit('UPDATETOKEN', null)
  socketClose()
  setTimeout(e => (logOut()), 500)
}

// 文本、图片前加域名
export const imgFilter = function (imgs) {
  let AD = imgs
  let imgUrl
  const imgReg = /[^>]*src=['"]([^'"]+)[^>]*>/gi// 定义正则，筛选出img元素
  if (AD.length !== 0) {
    const matchres = AD.match(imgReg)
    if (matchres) {
      matchres.forEach((item, index) => {
        const _tempStr = item.slice(0, item.length - 2)
        // _tempStr = _tempStr.replace('/prod-api', '');
        const _index = _tempStr.indexOf('/')
        if (item.indexOf('http') === -1 && item.indexOf('https') === -1) {
          imgUrl = globals.$imgUrl
        } else {
          imgUrl = ''
        }
        const _str = _tempStr.substring(0, _index) + imgUrl + _tempStr.substring(_index, _tempStr.length) + '"/>'

        AD = AD.replace(item, _str)
      })
      if (AD.indexOf('<img') > 0) {
        AD = AD.replace(
          /<img([\s\S]*?)src="/g,
          '<img width="100%" height="100%" style="object-fit: contain;" src="'
        )
        AD = AD.replace(
          /text-indent: ([\s\S]*?);/g,
          'text-indent: 0px;'
        )
      }
    } else if (
      AD.indexOf('.png') > 0 ||
      AD.indexOf('.jpg') > 0 ||
      AD.indexOf('.gif') > 0 ||
      AD.indexOf('.pdf') > 0 ||
      AD.indexOf('.mp4') > 0) {
      if (AD.indexOf('http') === -1 && AD.indexOf('https') === -1) {
        imgUrl = globals.$imgUrl
      } else {
        imgUrl = ''
      }
      AD = imgUrl + imgs
    }
  }
  return AD
}

// 判断是否为ios机型
function isIOS () {
  const u = navigator.userAgent
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  // if (isiOS) {
  //   this.isIos = true;
  // }else {
  //   this.isIos = false;
  // }
  return isiOS
}

// 跳转新页面，区分ios和安卓
export const jumpPage = async function (url) {
  if (isIOS()) {
    window.location.assign(url)
  } else {
    setTimeout(() => {
      window.open(url, '_blank')
    })
  }
}

// 计算年龄
export const myAge = function (birthday) {
  if (birthday) {
    // // const str = birthday
    // birthday = birthday.split('-')
    // 新建日期对象
    const date = new Date()
    // 今天日期，数组，同 birthday
    const today = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
    // 分别计算年月日差值
    const age = today.map((val, index) => {
      return val - birthday[index]
    })
    // 当天数为负数时，月减 1，天数加本月总天数
    if (age[2] < 0) {
      // 获取当月总天数的方法
      const curMonth = new Date(today[0], today[1], 0)
      age[1]--
      age[2] += curMonth.getDate()
    }
    // 当月数为负数时，年减 1，月数加上 12
    if (age[1] < 0) {
      age[0]--
      age[1] += 12
    }
    // console.log('出生日期：' + str + '  岁数：' + age[0] + '岁' + age[1] + '月' + age[2] + '天')
    return age[0]
  }
}

export const logOut = function (type) {
  if (store.state.token) {
    //  弹出提示
    showConfirmDialog({
      confirmButtonText: i18n.global.t('确认'),
      cancelButtonText: i18n.global.t('取消'),
      title: !type ? i18n.global.t('是否退出') : i18n.global.t('是否注销'),
      width: '220px'
    })
      .then(async () => {
        const res = await logout()
        if (res.code === 200) {
          router.push('/login')
          // localStorage.clear()
          removeMyInfo()
          socketClose()
        }
        // on confirm
      })
      .catch(() => {
        // on cancel
      })
  } else {
    router.push('/login')
  }
}

// 获取客服链接
export const servis = async function () {
  const res = await configFile({
    field: [
      'customer_service_link'
      // 'hide_inside_switch'
    ]
  })
  if (res.code === 200) {
    jumpPage(res.data.customer_service_link)
    // this.hide_inside_switch = res.data.hide_inside_switch;
  }
}

export const filterStopTime = function (myTime) {
  const res = myTime - parseInt(new Date().getTime() / 1000) < 0 ? 0 : ((myTime - parseInt(new Date().getTime() / 1000)) / 3600).toFixed(0)
  return res
}
