import { createI18n } from 'vue-i18n'
import cn from './message/cn'
import en from '@/assets/js/message/en'

const i18n = createI18n({
  locale: localStorage.getItem('lang') || 'cn', // 默认显示的语言
  fallbackLocale: localStorage.getItem('lang') || 'cn', // 预设语言环境
  globalInjection: true, // 全局生效$t
  legacy: false,
  messages: {
    cn: cn,
    en: en
  }
})

export default i18n
