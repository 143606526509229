import "core-js/modules/es.array.push.js";
import { onClickLeft, imgFilter, servis
// jumpPage
} from '@/assets/js';
import navbar from '@/components/navbar/index.vue';
import AddVipView from '@/components/AddVipView';
import { onMounted, reactive, toRefs } from 'vue';
import { globals } from '@/assets/js/global';
import { configFile } from '@/assets/js/api';
import { soket } from '@/assets/js/soket';
import { useRouter } from 'vue-router';
export default {
  components: {
    navbar,
    AddVipView
  },
  provide() {
    return {
      $back: onClickLeft,
      $imgFilter: imgFilter,
      $servis: servis
    };
  },
  setup() {
    const data = reactive({
      offset: {
        y: 20
      },
      jk_switch: '',
      isWeb: globals.$isWeb,
      soketStatus: true,
      token: localStorage.getItem('token')
    }); // 数据
    const {
      push
    } = useRouter();
    const methods = {
      onClick() {
        // servis()
        push('/checkLine');
        // jumpPage(localStorage.getItem('serveUrl'))
      },
      async onLoad() {
        const res = await configFile({
          field: ['socket_link']
        });
        if (res.code === 200) {
          localStorage.setItem('socket_link', res.data.socket_link);
        }
      }
    }; // 事件
    methods.onLoad();
    onMounted(() => {
      if (data.token) {
        soket();
        data.soketStatus = false;
      }
    });
    return {
      ...toRefs(data),
      ...methods
    };
  }
};