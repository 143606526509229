export default {
  UPDATETOKEN (state, res) {
    state.token = res
  },
  UPDATEINFO (state, res) {
    state.info = res
  },
  UPDATEADDVIP (state, res) {
    state.addvip = res
  },
  UPDATEADDVIPTYPE (state, res) {
    state.addvip = true
    state.addvipType = res
  }
}
