import { computed, reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'index',
  setup() {
    const {
      t
    } = useI18n();
    const route = useRoute();
    const data = reactive({
      active: computed({
        get: () => data.NavList.map(e => e.path).indexOf(route.path),
        set: val => val
      }),
      NavList: [{
        path: '/',
        ico: require('@/assets/img/navbar/1.png'),
        ico2: require('@/assets/img/navbar/11.png'),
        name: computed(e => t('首页'))
      }, {
        path: '/ins',
        ico: require('@/assets/img/navbar/2.png'),
        ico2: require('@/assets/img/navbar/22.png'),
        name: computed(e => t('保险理财'))
      }, {
        path: '/waybill',
        ico: require('@/assets/img/navbar/3.png'),
        ico2: require('@/assets/img/navbar/33.png'),
        name: computed(e => t('运单任务'))
      }, {
        path: '/order',
        ico: require('@/assets/img/navbar/4.png'),
        ico2: require('@/assets/img/navbar/44.png'),
        name: computed(e => t('订单列表'))
      }, {
        path: '/user',
        ico: require('@/assets/img/navbar/5.png'),
        ico2: require('@/assets/img/navbar/55.png'),
        name: computed(e => t('我的'))
      }]
    }); // 数据
    const methods = {}; // 事件
    return {
      ...toRefs(data),
      ...methods
    };
  }
};