import { configFile, bindClient } from '@/assets/js/api'
let cl = true
let sta = true
let soketTimes

let sokets = null
let isBind = false

export const soket = async () => {
  let socketLink
  socketLink = localStorage.getItem('socket_link')
  if (!socketLink) {
    const res = await configFile({
      field: ['socket_link']
    })
    if (res.code === 200) {
      socketLink = res.data.socket_link
      localStorage.setItem('socket_link', res.data.socket_link)
    }
  }

  sokets = new WebSocket(socketLink)
  // sokets = new WebSocket('wss://gw6yun.cn:8585')
  if (!localStorage.getItem('memberId')) {
    return
  }
  sokets.onopen = () => {
    if (soketTimes) {
      clearInterval(soketTimes)
    }
    soketTimes = setInterval(() => {
      if (sokets.readyState === WebSocket.OPEN) {
        sokets.send('ping')
      } else {
        run()
      }
    }, 20000)
    // console.log(soketTimes)
  }// 发送请求ping
  sokets.onmessage = async (e) => {
    const data = JSON.parse(e.data)
    if (!isBind) {
      const bindRes = await bindClient({
        client_id: data.data.client_id
      })
      if (bindRes.code === 200) {
        isBind = true
        // const data = JSON.parse(e.data)
        if (data.type === 'init') {
          const sendInfo = { type: 'init', data: { uid: localStorage.getItem('memberId') } }
          sokets.send(JSON.stringify(sendInfo))
        }
      }
    }
  }
  sokets.onerror = () => {
    console.log('websocket断连')
    // time = false
    run()
  }// 链接失败重连
  sokets.onclose = () => {
    console.log('关闭socket')
    if (sta) {
      sta = false
      run()
    }
  }// 链接关闭
}
export const socketClose = () => {
  sta = false
  if (sokets) {
    sokets.close()
  }
  clearInterval(soketTimes)
}
export const run = () => {
  if (cl) {
    setTimeout(() => {
      soket()
      cl = true
    }, 1000)
  }
  cl = false
}
